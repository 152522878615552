.registered {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #002858;
  font-size: 14px;
  background: #d1eff1;
  background: linear-gradient(180deg, #ffffff 0%, #d0eef0 50%);
}

.registered__container {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.registered__text {
  font-weight: 500;
}
