html,
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

#root {
  overflow-x: hidden;
}

h1,
h2 {
  font-family: 'Bebas Neue', cursive;
  font-weight: 400;
}

h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
