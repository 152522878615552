.await {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #002858;
  font-size: 14px;

  img {
    display: block;
  }
}

.await__container {
  background-color: #d1eff1;
  flex-grow: 1;

  @media screen and (min-width: 600px) {
    background: linear-gradient(180deg, white 0%, #d1eff1 65%);
    padding-top: 30px;
  }
}

.await__inner-container {
  @media screen and (min-width: 600px) {
    display: flex;
    max-width: 520px;
    margin: 0 auto;
    align-items: center;
  }
}

.await__main-image--background {
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(179deg, white 55%, #d1eff1 85%);

  @media screen and (min-width: 600px) {
    display: none;
  }
}

.await__main-image--container,
.await__main-image-desktop--container {
  position: relative;
}

.await__main-image-desktop--container {
  display: none;
  @media screen and (min-width: 600px) {
    display: block;
  }
}

.await__main-image {
  width: 200px;
}

.await__main-image-desktop {
}

.await__main-image--icon {
  position: absolute;
  top: 136px;
  left: 34px;
  &.loading {
    animation: rotate-center 1.5s linear infinite both;
  }

  @media screen and (min-width: 600px) {
    top: 142px;
    left: 18px;
  }
}

.await__steps-container {
  width: calc(100% - 40px);
  max-width: 500px;
  margin: 0 auto;
  font-weight: 500;

  @media screen and (min-width: 600px) {
    margin: 0 0 0 auto;
    width: calc(100% - 200px);
  }
}

.await__progress-bar--texts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.await__progress-bar {
  background-color: #dbe1e5;
  height: 6px;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 20px;
}

.await__progress-bar--progress {
  background-color: #5fda73;
  border-radius: 3px;
  height: 100%;
  transition: width 50ms ease-out;
}

.await__step {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
}

.await__step--img {
  &.loading {
    animation: rotate-center 1.2s linear infinite both;
  }
}

.await__step--text {
  color: #648396;
  margin-left: 10px;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
