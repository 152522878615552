.loader--container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1eff1;
}

.loader {
  border: 5px solid rgba(0, 0, 0, 0.2);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-left-color: #002858;

  animation: spin 1s cubic-bezier(0.67, 0.4, 0.49, 0.78) infinite both;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
