.awaitPremium {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #4e5e6e;
  font-size: 14px;

  img {
    display: block;
  }
}

.headerPremium {
  display: flex;
  height: 60px;
  justify-content: center;
  background-color: #002143;
  z-index: 1;
}

.headerPremium__container {
  width: calc(100% - 50px);
  padding-top: 10px;
  max-width: 1024px;
}

.headerPremium__logo {
  height: 60px;
}

.awaitPremium__main {
  background-color: #f2f2f2;
  flex-grow: 1;
  padding: 25px 0;

  @media screen and (min-width: 600px) {
    padding-top: 50px;
  }
}

.awaitPremium__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 25px;
  width: calc(100% - 25px);
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  min-height: 440px;
}

.awaitPremium__step {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.awaitPremium__step--text {
  margin: 0;
  max-width: calc(100% - 25px);
}

.awaitPremium__spinning {
  animation: rotate-center 1.2s linear infinite both;
}

.awaitPremium__progressBar {
  background-color: #f2f2f2;
  height: 6px;
  width: 50%;
  border-radius: 3px;
  margin: 30px 0 10px;
}

.awaitPremium__progress {
  background-color: #00eae2;
  border-radius: 3px;
  height: 100%;
  width: 0%;
  animation: progress 4.5s linear 0.2s forwards;
}

.footer-premium {
  padding: 15px;
  background-color: #f2f2f2;
  margin-top: auto;
  font-size: 14px;
  font-weight: 600;
  color: #002858;
  text-align: center;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  9% {
    width: 17%;
  }
  20% {
    width: 21%;
  }
  33% {
    width: 33%;
  }
  45% {
    width: 46%;
  }
  57% {
    width: 61%;
  }
  66% {
    width: 66%;
  }
  85% {
    width: 90%;
  }
  93% {
    width: 91%;
  }
  100% {
    width: 100%;
  }
}
