.error-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #002858;
  background: #d1eff1;
}

.error-page--code {
  font-size: 100px;
  margin: 0;
}

.error-page--text {
  font-size: 35px;
  margin: 20px 0 0;
}
