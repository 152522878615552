.header {
  padding: 0;
}

.header__inner-container {
  padding: 20px 20px 15px;
  max-width: 1000px;
  margin: 0 auto;
}

.header__logo {
  height: 30px;
  display: block;

  @media screen and (min-width: 600px) {
    height: 35px;
  }
}
