$main-color: rgb(106, 130, 154);
$lines-color: #90a1b2;
$main-blue: #002858;
$premium-blue: #002143;
$main-green: #7ae261;
$btn-green-shadow: rgba(94, 219, 115, 0.5);

.survey {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, white 0%, #e0f2f4 100%);

  &.premium {
    background: #f2f2f2;
  }

  .header {
    width: calc(100% - 60px);
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $lines-color;

    &.premium {
      height: 60px;
      padding-top: 11px;
      background-color: $premium-blue;
      border-bottom: none;
      width: 100%;
    }
  }

  .header__logoPremium {
    height: 60px;
  }

  .ad {
    margin-top: 15px;
    text-align: center;
    font-family: 'Bebas Neue', cursive;
    font-size: 1.5em;
    line-height: 1;
    color: $main-blue;

    @media screen and (min-width: 600px) {
      font-size: 2em;
    }
  }

  .sv_main.sv_default_css {
    &,
    & .sv_body,
    & .sv_p_root > .sv_row:nth-child(2n + 1),
    .sv_p_root > .sv_row:nth-child(2n) {
      background-color: transparent;
    }

    input {
      font-family: inherit;
    }

    input:not([type='button']):not([type='reset']):not([type='submit']):not([type='image']):not([type='checkbox']):not([type='radio']) {
      border-radius: 5px;
      border-color: $lines-color;
      color: $main-blue;
      -webkit-text-fill-color: $main-blue;
      font-weight: 500;

      &::placeholder {
        color: $main-color;
        -webkit-text-fill-color: $main-color;
      }
    }

    .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper .sv_q_dropdown_control,
    .sv_q_dropdown_control {
      background-color: transparent;
      border-color: $lines-color;
      border-radius: 5px;
      color: $main-blue;
    }

    .sv_container .sv_body .sv_p_root .sv_q input[type='radio']:focus {
      outline: none;
    }
  }

  .sv_main .sv_p_root > .sv_row {
    border-bottom: none;
  }

  .sv_qstn {
    .sv-q-col-1,
    .sv-q-column-2 {
      padding: 0;
    }
  }

  .sv_q_checkbox + .sv_q_checkbox,
  .sv_q_radiogroup + .sv_q_radiogroup {
    margin-top: 0.5em;
  }

  .sv_main .sv_container .sv_body {
    .sv_progress {
      height: 0.5em;
      background: #d7eff1;
      margin-bottom: 1.2em;

      .sv_progress_bar {
        margin-top: 0;
        min-width: initial;
        border-radius: 0.3em;
      }
    }

    .sv_p_root {
      .sv_q {
        padding: 0;

        .sv_q_erbox:not([style*='display: none']):not([style*='display:none']) {
          padding: 0.5em;
          margin: 0.5em 0 0;
        }

        input:not([type='button']):not([type='reset']):not([type='submit']):not([type='image']):not([type='checkbox']):not([type='radio']) {
          padding-left: 0;
          padding: 0 0.5em;
          font-size: 0.9em;
          background-color: transparent;
        }

        .sv_select_wrapper {
          background-color: transparent;

          &:before {
            padding: 0;
            background: none;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $main-blue;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
          }

          select {
            padding-right: 1.5em;
          }
          .sv_q_dropdown_control {
            font-family: inherit;
            font-size: 0.9em;
          }
        }

        div.sv_q_dropdown_control {
          min-height: 2em;
          font-size: 0.9em;
        }
      }

      .sv_q[name='terms'] input[type='checkbox'] {
        font-size: 0.8em;
        flex-shrink: 0;
      }

      .sv_q_title,
      .sv_p_title {
        font-weight: 500;
        color: $main-blue;
        margin-top: 0;
      }

      .sv_p_title {
        padding: 0;
        margin-bottom: 0.5em;
      }

      .sv_q:not([name='terms']) input,
      .sv_q input[type='radio'] {
        appearance: none;
        width: 0;
        height: 0;
        margin-right: 0 !important;

        &:focus {
          outline: none;
        }
      }

      .sv_q[name='terms'] {
        fieldset.sv_qcbc {
          line-height: 2em;
        }

        .sv_q_checkbox_label {
          display: flex;
          align-items: center;
          font-size: 0.8em;
          line-height: 1.2em;
        }
      }
    }

    .sv_nav {
      padding: 0;
      display: flex;
      margin-top: 1em;
      position: relative;

      .sv_start_btn,
      .sv_next_btn,
      .sv_complete_btn {
        width: 100%;
        margin-right: 0 !important;
        height: 2.5em;
        color: $main-blue;
        font-family: inherit;
        border-radius: 1.5em;
        font-size: 1em;
        text-transform: uppercase;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        background: url('../../images/flecha.png') no-repeat;
        width: 15px;
        height: 18px;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
      }
    }
  }

  #terms,
  #privacy {
    text-decoration: underline;
    cursor: pointer;
  }

  .footer {
    background-color: white;
    margin-top: initial;
    background-color: initial;
    border-top: 1px solid $lines-color;
    width: calc(100% - 60px);

    p {
      color: $main-color;
      font-weight: 600;
    }

    &.premium p {
      font-size: 14px;
      font-weight: 600;
      color: $main-blue;
    }
  }
}

.sv_custom_header {
  display: none;
}

.survey__container {
  flex-grow: 1;
  overflow: hidden;
  padding-top: 0.75em;
}

.sv_progress {
  position: relative;
  border-radius: 0.3em;
}

.sv_progress_text {
  position: absolute;
  bottom: 1em;
  font-size: 15px;
  font-weight: 600;
  color: $main-blue;
}

.sv_main .sv_container .sv_body {
  padding: 40px 30px 20px;
}

.sv_main {
  font-family: inherit;
  font-size: 18px;
  max-width: 500px;

  .sv_body {
    border-top: none;
  }
}

.sv_row + .sv_row {
  margin-top: 1.2em;
}

.sv_qstn .sv-q-column-2 {
  width: calc(50% - 0.25em);

  &:nth-child(even) {
    margin-left: 0.5em;
  }
}

.sv_q_erbox {
  border-radius: 5px;
  font-size: 0.7em;
}

.sv_q_radiogroup_label,
.sv_q:not([name='terms']) .sv_q_checkbox_label {
  border: 1px solid $lines-color;
  font-size: 0.9em;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s;
  position: relative;
  padding: 0.1em 1.5em;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 9px;
    left: 12px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: transparent;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    transition: border-color 0.3s;
  }
  &.checked {
    background-color: $main-green;
    color: $main-blue;
    border-color: $main-green;

    &::before {
      border-color: $main-blue;
    }
  }
}

.sv_q:not([name='terms']) .sv_q_checkbox.checked .sv_q_checkbox_label {
  background-color: $main-green;
  color: $main-blue;
  border-color: $main-green;

  &::before {
    border-color: $main-blue;
  }
}

.sv_imgsel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sv_q_imgsel {
  width: calc(50% - 0.2em);
  margin-right: 0 !important;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background: $main-green url('../../images/check.png') no-repeat center;
    background-size: 16px;
    width: 1.7em;
    height: 1.7em;
    bottom: 0.5em;
    left: 0.5em;
    border-radius: 1em;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.checked::after {
    opacity: 1;
  }

  &:nth-of-type(n + 3) {
    margin-top: 0.4em;
  }
}

.sv_q_imgsel_label > div {
  transition: background-color 0.3s;
}

.sv_q_imgsel_image {
  width: 100%;
  height: auto;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q[name='sex'] .sv_q_radiogroup_label {
  display: flex;
}

.sv_q[name='sex'] {
  color: $main-blue;

  .sv_q_radiogroup_label {
    background-repeat: no-repeat;
    background-size: 2.5em;
    background-position: center 25%;
    align-items: flex-end;
    justify-content: center;
    height: 5.5em;
  }

  .sv_q_select_column:nth-child(1) .sv_q_radiogroup_label {
    background-image: url('../../images/icon_hombre.png');
  }
  .sv_q_select_column:nth-child(2) .sv_q_radiogroup_label {
    background-image: url('../../images/icon_mujer.png');
  }
}

.sv_q[name='dateOfBirth'] {
  .sv_q_mt_title {
    display: none;
  }

  .sv_q_mt_row > td:nth-of-type(4n + 2) {
    padding: 0 !important;
  }

  .sv_q_text_root {
    padding-left: 0.5em;
    text-align: center;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.google-policies {
  color: $main-blue;
  width: calc(100% - 60px);
  margin: 0 auto 5px;
  max-width: max-content;
}

.hide {
  animation: hide 0.4s ease-in;
}

.show {
  animation: show 0.4s ease-out;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.question_content.loading {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.4em;
    right: 0.4em;
    border: 3px solid rgba(0, 0, 0, 0.2);
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border-left-color: $main-blue;

    animation: spin 1s cubic-bezier(0.67, 0.4, 0.49, 0.78) infinite both;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
