.footer {
  padding: 15px;
  background-color: #f2f2f2;
  margin-top: auto;

  p {
    text-align: center;
    font-weight: bold;
    color: #9b9b9b;
    font-size: 12px;
  }
}
